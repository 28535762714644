// 领取取代金券


import {
  post,
  get
} from '@get/http'


export const getReceiveVoucher = (tid) => {
  return post(
    '/Membervoucher/voucher_point', {
      data: {
        tid
      }
    }
  )
}


export const getVoucherList = (page, per_page) => {
  return post(
    '/Membervoucher/voucher_list', {
      data: {
        page,
        per_page,
      }
    }
  )
}

export const getProductList = () => {
  return post(
    '/Index/getProductList'
  )
}


export const getShowVoucher = (vouchertemplate_id) => {
  return post(
    '/Voucher/showVoucher', {
      data: {
        vouchertemplate_id
      }
    }
  )
}